import { isActorInput } from '../builders/actor';
import { evaluateAndFlatten } from '@m10s/utils';
import warnOnce from '../warnOnce';
/**
 * @internal exported for testing
 */
export const WARN_CIS_SYNC_LOGIN_UNDEFINED = 'Cis sync initiated with no actor builder and no event available. If you do not use a login system, please indicate' +
    ' this using tracker.update({ actor: undefined }), otherwise do tracker.update({ actor: somePromise }) with a' +
    ' promise that resolves to the login state.';
/**
 * Get the actor id (userId) from the current browser. A promise is returned
 * because a call may be made to complete the login process.
 *
 * *Note*: This method will return a Promise that is always rejected on node
 environments.
 @category Identifiers
 */
const getUserId = async (builders) => {
    const { id } = await getActor(builders);
    return id;
};
/**
 * @category Identifiers
 */
const getActor = async (builders) => {
    return await evaluateActor(builders);
};
const userIsLoggedIn = async (builders) => {
    const actor = await getActor(builders);
    return !!actor?.id;
};
/**
 * Evaluate actor input given to the sdk/this method and pass it through the
 * Actor event formatter.
 * @category Tracker
 * @private
 */
const evaluateActor = async (eventInput = {}, builders = {}) => {
    if (isActorInput(eventInput) || isActorInput(builders)) {
        return ((await evaluateAndFlatten(eventInput.actor || builders.actor || {})) ||
            {});
    }
    // We allow an empty actor object, because it is a sign that the application is not setting nor updating Actor correctly.
    // Inferring "id: undefined" as a fallback would mask that bug.
    // Hence, the warning below.
    warnOnce('no-actor-builder-and-no-event-present', WARN_CIS_SYNC_LOGIN_UNDEFINED);
    return {};
};
export { getUserId, getActor, userIsLoggedIn, evaluateActor };
