/* eslint-env browser */
/* eslint func-names: 0 */

import { isFunction, isString, pulseMerge, get as _get } from '@m10s/utils';

// Will be replaced by rollup-replace-plugin to create media/m10s builds of autotracker. See
// rollup.config.js.
import Tracker from '<@SDK_IMPLEMENTATION@>';

import debug from './debug';
import defer from './defered';
import sortQueue from './queueSort';

const AUTOTRACKER_VERSION = '<@AUTOTRACKER_VERSION@>';

const DEFAULT_TRACKER_NAME = 'defaultTracker';

const trackers = {};
const plugins = {};

function create({ trackerName = DEFAULT_TRACKER_NAME, providerId, config, builders } = {}) {
  // Special support for Hybrid apps, using Pulse tracking and/or ad identifiers within a webview
  const nativeJweConfig =
    !window.__nativeJwe__ && !window.hermesJwe
      ? {}
      : {
          nativeJwe: window.__nativeJwe__ || window.hermesJwe,
        };

  // If the pulse event handler is set, e.g. by a native app in
  // a hybrid app setup, we pick that as the 'altEventHandler'
  const eventHandlerConfig = !window.pulseEventHandler
    ? {}
    : {
        altEventHandler: window.pulseEventHandler,
      };

  trackers[trackerName] = new Tracker(
    providerId,
    pulseMerge({}, nativeJweConfig, eventHandlerConfig, config),
    pulseMerge(
      {
        tracker: {
          autoTrackerVersion: AUTOTRACKER_VERSION,
        },
      },
      builders,
    ),
  );
  return trackers[trackerName];
}

let paused = false;

function executeCallQueue() {
  try {
    // Update queue in-place.

    window.pulse.q.sort(sortQueue);

    const { q: queue } = window.pulse;
    if (
      paused &&
      queue.find((i) => {
        const value = i instanceof Promise ? i.inputValue[0] : i[0];
        return value === 'provide';
      })
    ) {
      paused = false;
    }

    let currCall = !paused && queue.shift();
    let currentPromise;
    while (!paused && currCall) {
      try {
        if (currCall instanceof Promise && currCall.inputValue) {
          currentPromise = currCall;
          currCall = currentPromise.inputValue;
        } else {
          currentPromise = defer(currCall);
        }
        const [name = '', ...rest] = currCall;
        const useDefaultTracker = isString(name) && name.indexOf('.') === -1;

        if (isFunction(name)) {
          name(trackers[DEFAULT_TRACKER_NAME]);
          currentPromise.resolve(trackers[DEFAULT_TRACKER_NAME]);
        } else if (name === 'init') {
          const [providerId, config, builders, trackerName] = rest;
          currentPromise.resolve(
            create({
              providerId,
              config,
              builders,
              trackerName,
            }),
          );
        } else if (name === 'provide') {
          const [pluginName, prototype] = rest;
          plugins[pluginName] = prototype;
          currentPromise.resolve();
          paused = false;
        } else if (isString(name) && name.indexOf('require') !== -1) {
          const trackerName = useDefaultTracker ? DEFAULT_TRACKER_NAME : name.split('.')[0];
          const [pluginName, config = {}] = rest;
          if (!plugins[pluginName]) {
            paused = true;
            window.pulse.q.push(currentPromise);
            return;
          }
          const PluginClass = plugins[pluginName];
          trackers[trackerName][pluginName] = new PluginClass(trackers[trackerName], config);
        } else {
          const [trackerName, methodName] = useDefaultTracker
            ? [DEFAULT_TRACKER_NAME, name]
            : [name.split('.')[0], name.split('.').splice(1).join('.')];
          if (methodName === '') {
            if (isFunction(rest[0])) {
              rest[0](trackers[trackerName]);
            }
            currentPromise.resolve(trackers[trackerName]);
          } else if (trackers[methodName]) {
            // pulse('namedTracker', namedTracker => {})
            if (isFunction(rest[0])) {
              rest[0](trackers[methodName]);
            }
            currentPromise.resolve(trackers[methodName]);
          } else {
            const methodPath = methodName.split('.');
            const contextPath =
              methodName.indexOf('.') === -1 ? trackerName : `${trackerName}.${methodPath.slice(0, -1).join('.')}`;
            const context = _get(trackers, contextPath);
            const result = context[methodPath[methodPath.length - 1]](...rest);
            if (isFunction(rest[0])) {
              rest[0](result);
            }
            currentPromise.resolve(result);
          }
        }
      } catch (err) {
        debug(err);
        currentPromise.reject(err);
      }
      currCall = queue.shift();
    }
  } catch (err) {
    debug(err);
  }
}

window.pulse =
  window.pulse ||
  function (...rest) {
    const promise = defer(...rest);
    (window.pulse.q = window.pulse.q || []).push(promise);
    return promise;
  };

window.pulse.q = window.pulse.q || [];

window.pulse.q.push = function (...rest) {
  Array.prototype.push.apply(this, rest);
  setTimeout(executeCallQueue, 1);
  return this.length;
};

setTimeout(executeCallQueue, 1);
