import { isPlainObject, isPromise } from './objects';
/**
 * This function works like lodash deep merge, but in addition it also merges the result of promises.
 */
export function pulseMerge(target, ...sources) {
    // mutates target
    function innerMerge(target, source) {
        for (const [key, value] of Object.entries(source)) {
            if (value === null) {
                target[key] = value;
            }
            else {
                // @ts-ignore
                target[key] = mergeValue(target[key], value);
            }
        }
    }
    function mergeValue(targetValue, value) {
        if (Array.isArray(value)) {
            if (!Array.isArray(targetValue)) {
                return [...value];
            }
            else {
                for (let i = 0, l = value.length; i < l; i++) {
                    targetValue[i] = mergeValue(targetValue[i], value[i]);
                }
                return targetValue;
            }
        }
        else if (isPromise(targetValue) || isPromise(value)) {
            return Promise.all([targetValue, value]).then(([targetValue, value]) => {
                // If plain object or any promises we merge recursively, if not we just pick the new value
                const shouldRecursiveMerge = isPlainObject(value) || isPromise(targetValue);
                return shouldRecursiveMerge ? mergeValue(Object.assign({}, targetValue), value) : value;
            });
        }
        else if (typeof value === 'object') {
            if (targetValue && typeof targetValue === 'object') {
                innerMerge(targetValue, value);
                return targetValue;
            }
            else {
                return value ? { ...value } : value;
            }
        }
        else {
            return value ?? targetValue ?? undefined;
        }
    }
    for (const source of sources) {
        mergeValue(target, source);
    }
    return target;
}
